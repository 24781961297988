import { Typography } from '@material-ui/core'
import { graphql, PageProps as GatsbyPageProps } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '../components/layout'
import config from '../../config.json'

type PageProps = { data: GatsbyTypes.PageQuery } & GatsbyPageProps

const Page = ({ data: { file }, location }: PageProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('content')
    }
  }, [])
  if (
    !file?.childMarkdownRemark?.html ||
    !file.childMarkdownRemark.frontmatter?.title
  ) {
    return null
  }

  const {
    childMarkdownRemark: {
      html,
      frontmatter: { title, description, noIndex },
    },
  } = file

  const {
    seo: {
      website: { description: fallbackDescription },
    },
  } = config

  return (
    <Layout
      showAds={false}
      location={location}
      seoProps={{
        title,
        description:
          !description || description === ''
            ? fallbackDescription
            : description,
        noIndex,
      }}
    >
      <Typography component="h1" variant="h2" gutterBottom>
        {title}
      </Typography>

      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query Page($id: String!) {
    file(id: { eq: $id }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
          description
          noIndex
        }
      }
    }
  }
`
